import { StyleSheet, Text, View } from "@react-pdf/renderer";
import config from "./SummaryTableConfig";
import _ from "lodash";

const UNIQUE_SIZE_WIDTH = 40;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    backgroundColor: config.headerBackgroundColor,
    alignItems: "center",
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
    fontSize: config.headerFontSize,
    borderColor: config.headerBorderColor,
  },
  headerItem: {
    fontFamily: "Times-Bold",
    height: "100%",
    //alignItems: "center",
    paddingTop: 10,
  },
});

const SummaryTableHeader = ({ uniqueSizes }) => (
  <View wrap={false}>
    <View style={{ ...styles.container, backgroundColor: "#eee" }}>
      <Text
        style={{
          ...styles.headerItem,
          borderRight: 1,
          borderBottom: 0,
          paddingBottom: 5,
          paddingTop: 10,
          width: "10%",
          height: "100%",
        }}
      >
        PO Number
      </Text>
      <Text style={{ ...styles.headerItem, borderRight: 1, width: "10%" }}>
        Style
      </Text>
      <Text style={{ ...styles.headerItem, borderRight: 1, width: "10%" }}>
        SHADE
      </Text>
      <Text
        style={{
          ...styles.headerItem,
          borderRight: 1,
          width: `10%`,
        }}
      >
        {/* FIELDS */}
      </Text>
      <Text
        style={{
          ...styles.headerItem,
          borderRight: 1,
          borderBottom: 1,
          width: `${UNIQUE_SIZE_WIDTH}%`,
          paddingBottom: 5,
          paddingTop: 5,
        }}
      >
        SIZE
      </Text>
      <Text
        style={{
          ...styles.headerItem,
          borderRight: 1,
          width: "10%",
          paddingRight: 10,
        }}
      >
        TOTAL PACKS
      </Text>
      {/* <Text style={{ ...styles.headerItem, width: "10%", borderRight: 1 }}>
        TOTAL PCS
      </Text> */}
      <Text style={{ ...styles.headerItem, width: "10%", borderRight: 1 }}>
        TOTAL CTN
      </Text>
    </View>
    {/* Subheader */}
    <View style={{ ...styles.container, backgroundColor: "#eee" }}>
      <View
        style={{
          height: "100%",
          width: "10%",
          borderBottom: 1,
          borderRight: 1,
          borderColor: "black",
        }}
      ></View>
      <View
        style={{
          //...styles.headerItem,
          height: "100%",
          borderRight: 1,
          borderBottom: 1,
          width: "10%",
        }}
      />
      <View
        style={{
          //...styles.headerItem,
          height: "100%",
          borderRight: 1,
          borderBottom: 1,
          width: "10%",
        }}
      />
      <View
        style={{
          ...styles.headerItem,
          borderRight: 1,
          borderBottom: 1,
          width: `10%`,
        }}
      />
      {_.map(uniqueSizes, (value, key) => (
        <Text
          key={key}
          style={{
            ...styles.headerItem,
            borderRight: 1,
            borderBottom: 1,
            paddingTop: 5,
            paddingBottom: 5,
            width: `${UNIQUE_SIZE_WIDTH / uniqueSizes.length}%`,
          }}
        >
          {value}
        </Text>
      ))}
      {/* <Text
        style={{
          ...styles.headerItem,
          borderRight: 1,
          borderBottom: 1,
          width: "10%",
        }}
      ></Text> */}
      <Text
        style={{
          ...styles.headerItem,
          borderBottom: 1,
          width: "10%",
          borderRight: 1,
        }}
      ></Text>
      <Text
        style={{
          ...styles.headerItem,
          borderBottom: 1,
          width: "10%",
          borderRight: 1,
        }}
      ></Text>
    </View>
  </View>
);

export default SummaryTableHeader;
