import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { CookiesProvider } from "react-cookie";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  createTheme,
  CssBaseline,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import { ErrorBoundary } from "react-error-boundary";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";

import reportWebVitals from "./reportWebVitals";
import store from "./app/redux/store";
import App from "./App";

import "./index.css";
import "./esGlobalStyle.css";
import "./reactFlterTable.css";
import "react-loading-skeleton/dist/skeleton.css";
import ScrollToTop from "./app/common/ScrollToTop";
import AppLogo from "./logo.svg";

const thumbColor = "#F64E60";
const scrollbarColor = "transparent";
const thumbColorFocus = "#fe8766";
const thumbColorActive = "#fe8766";
const thumbColorHover = "#fe8766";

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
        },
        "*": {
          //scrollbarWidth: "thin",
          //scrollbarColor: scrollbarColor,
          "&::-webkit-scrollbar": {
            width: 8,
            height: 6,
            backgroundColor: scrollbarColor,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: 6,
            backgroundColor: thumbColor,
            minHeight: 16,
            minWidth: 16,
          },
          "&::-webkit-scrollbar-thumb:focus": {
            backgroundColor: thumbColorFocus,
          },
          "&::-webkit-scrollbar-thumb:active": {
            backgroundColor: thumbColorActive,
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: thumbColorHover,
          },
          "&::-webkit-scrollbar-corner": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  },
});

const Loading = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <Typography variant="h6">Please wait... Checking Latest Build</Typography>
    </div>
  );
};
const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div class="error-boundary" role="alert">
      <img
        src={AppLogo}
        className="max-h-75px"
        alt=""
        style={{ margin: "auto" }}
      />
      <br />
      <p>Something went wrong</p>
      <pre>{error.message}</pre>
      <br />
      <button
        className="btn btn-primary btn-pill shadow-sm py-4 px-9 font-weight-bold"
        onClick={resetErrorBoundary}
      >
        Try again
      </button>
    </div>
  );
};

ReactDOM.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
    metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
  >
    <MuiThemeProvider theme={theme}>
      <CssBaseline>
        <ScrollToTop showBelow={100} />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <CookiesProvider>
            <SnackbarProvider maxSnack={3}>
              <Provider store={store}>
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onReset={() => {
                    window.location.assign("/app/dashboard");
                  }}
                >
                  <App />
                </ErrorBoundary>
              </Provider>
            </SnackbarProvider>
          </CookiesProvider>
        </MuiPickersUtilsProvider>
      </CssBaseline>
    </MuiThemeProvider>
  </CacheBuster>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
