// export const baseurl = "http://psl.ds-1.pratibhasyntex.com:8000/";
// export const baseUrlWithoutPort = "http://psl.ds-1.pratibhasyntex.com:3000/";
// export const fileDownloadUrl =
//   "http://51.79.255.71/sysdevelopment/en/neoclassic/";
// export const loginPM =
//   "http://psl.ds-1.pratibhasyntex.com/workflow/oauth2/token";
// export const pmPath = "http://51.79.255.71/plugin/pratibhaEngine/";
// export const sapbaseurl = "http://psl.ds-1.pratibhasyntex.com:8000/";
// export const reportbaseurl = "http://psl.ds-1.pratibhasyntex.com:8000/";

// export const baseurl = "http://psl.ds-2.pratibhasyntex.com:8000/";
// export const baseUrlWithoutPort = "http://psl.ds-2.pratibhasyntex.com/"
// export const fileDownloadUrl =
//   "http://51.79.255.71:8880/sysworkflow/en/neoclassic/";
// export const loginPM = "http://51.79.255.71:8880/workflow/oauth2/token";
// export const pmPath = "http://51.79.255.71:8880/plugin/pratibhaEngine/";

export const sapbaseurl = "http://pace.pratibhasyntex.com:8001/";
export const reportbaseurl = "http://pace.pratibhasyntex.com:8002/";
export const baseurl = "http://pace.pratibhasyntex.com:8000/";
export const baseUrlWithoutPort = "http://pace.pratibhasyntex.com/";
export const fileDownloadUrl =
  "http://51.79.255.71:8080/sysworkflow/en/neoclassic/";
export const loginPM = "http://51.79.255.71:8080/workflow/oauth2/token";
export const pmPath = "http://51.79.255.71:8080/plugin/pratibhaEngine/";

export const login = "auth/login";
export const listProcessData = "process/";

export const documentsList = "documents/";
export const users = "users/list";
export const getcartonslist = "cartons/get";
export const getcartonslistbyfilter = "cartons/dropdown";
export const getCartonsDetail = "cartons/get"; // post ["1","2",....carton IDs]
export const getCartonsListbyBuyer = "cartons/dropdown"; //get q="buyer_id"

export const addTicket = "ticket";
export const replyTicket = "ticket/reply";
export const profile = "profile";
export const brandslist = "brands";

//file APi's
export const uploadFileToServer = `${baseurl}extras/file/upload`; //Upload any file it will return id..!
export const getFileUrlFromServer = `${baseurl}extras/file/geturl`; //${baseurl}extras/file/geturl/${fileId}

//Consignee Set API
export const getInvoices = `${baseurl}cases/getInvoices`;

//Create all 9 Material Code
export const createall9materialcodes = `${sapbaseurl}sap/createall9materialcodes`;

//Scanning API
export const getCasesScanList = `${baseurl}cases/packingscancaseslist`;
export const getpldataforscanning = `${baseurl}cases/getpldataforscanning`; // url/id , id from getcasesscanlist
export const updatePlScanData = `${baseurl}cases/updatepldata`; //  '{ "id" : 1, "scanned_qty" : 25, "packed_carton" : 3 }'
export const getItemsFromBarCode = `${baseurl}cases/barcode/getdetails`; // params barcode=979523239114'
export const getCartonDetailsFromBarCode = `${baseurl}cases/barcode/getcartondetails`; // params barcode=979523239114'
export const updatePlScanStatus = `${baseurl}cases/updatePLStatus`; //  '{ "id" : 1, "scanned_qty" : 25, "packed_carton" : 3 }'

//Blends can be used for both Yarn Blend and Fabric Blend
export const getSubstrates = `${sapbaseurl}sap/getsubstrates`;
export const getFabricTypes = `${sapbaseurl}sap/getfabrictypes
`;

/* es */
export const getCustomers = `${baseurl}customer/dropdown`;
export const createProjectionOrder = `${sapbaseurl}sap/createprojectionorder`;
export const getBuyers = `${baseurl}brands`;
export const logoutUser = `${baseurl}auth/logout`;
export const getRefreshToken = `${baseurl}auth/refresh`;
export const getGreigeFabricList = `${baseurl}extras/greige`;
export const getGreigeVariantList = `${baseurl}extras/greigevariant`; //  /extras/greigevariant?generic_id=1600000106
export const getStyleList = `${baseurl}styles/list`;
export const addPDData = `${baseurl}styles/addpddata`;
export const addPatternMasterData = `${baseurl}styles/addpatternmasterdata`;
export const getStyleData = `${baseurl}styles/get`; // ${baseurl}styles/get/id
export const postAddElement = `${baseurl}styles/addelements`; //FAbric Style app/fabric_style/2060010184
export const getStyleElements = `${baseurl}styles/getstyleelements`; //getstyleelements/style_id
export const getStyleTabData = `${baseurl}styles/getstyletabdata`; //getstyletabdata/style_id

//Gate passs API's
export const getInvoiceByBuyer = `${sapbaseurl}sapsoinvoice/getInvoice`; // buyer:4
export const getDeliveryNoteNumber = `${sapbaseurl}sapsoinvoice/deliveryNumber`; // invoice:0411006774
export const getSAPTransporters = `${sapbaseurl}sapsoinvoice/transporter`;
export const addGatePass = `${sapbaseurl}sapsoinvoice/gatePass`;
export const getGatePassList = `${sapbaseurl}sapsoinvoice/getListGatePass`;
export const getStateList = `${sapbaseurl}sapsoinvoice/state_list`;
export const getMaterials = `${sapbaseurl}sapsoinvoice/getMaterials`;
export const submitManualGatePass = `${sapbaseurl}sapsoinvoice/manual_gatepass`;

//Bonds
export const createBond = `${baseurl}/bond/create`;
export const getBondsList = `${baseurl}bond/list`; // {id:buyer_id}
export const getBondsListDetail = `${baseurl}bond/bondListDetails`; //{id:bond_id}

//Vehicle Inward OutwardApi's
//https://pratibhasyntex.atlassian.net/wiki/spaces/PDT/pages/119767041/Vehicle+Planning+APIs
export const getVehicleEnteredList = `${baseurl}vp/listEnteredVehicles`;
export const getVehicleVendorsList = `${baseurl}vp/listVendors`;
export const getVehicleTypes = `${baseurl}vp/listVehicleTypes`;
export const addVehicleEntry = `${baseurl}vp/vehicle_entry`;
export const getVehicleList = `${baseurl}vp/listVehicles`; //vehicle_status:"OUT | IN"
export const getVehicleDetails = `${baseurl}vp/getVehicleDetails`; // {params:vehicleId:6}
export const addVehicleExit = `${baseurl}vp/vehicle_exit`; // {params:vehicleId:6}
export const getVehicleNumbersList = `${baseurl}vp/listVehicleNumbers`; //
export const addTransporter = `${baseurl}vp/addTransporter`;
export const deleteTransporter = `${baseurl}vp/deleteTransporter`;
export const updateTransporter = `${baseurl}vp/updateTransporter`; //post with "tr_id" : "1001004",
//post with "tr_id" : "1001004",

//Get vendorTypes
export const getVendorTypes = `${baseurl}vp/listVendorsTypes`;

//Vehicle Inward OutwardApi's

//Charts Api
/*{"buyer":"1", "month": "09", "year": "2021"}*/
export const getShipmentModes = `${baseurl}cases/po_shipmentmode`;
export const getOverallShipments = `${reportbaseurl}reports/overallShipments`;
/* {"buyer":"1", "month": "09", "year": "2021",shipment_mode:"1"}*/
export const getOverallShipmentsList = `${reportbaseurl}reports/overallShipmentsList`;
//PO PL Booking Chart (Stage Breakup)
export const getStageBreakup = `${reportbaseurl}reports/stagebreakup`;
export const getStageBreakupList = `${reportbaseurl}reports/stagebreakupList`;

//delayed Shipment
export const getDelayedShipmentsCount = `${reportbaseurl}reports/delayedShipments`;
export const getDelayedShipmentsCountList = `${reportbaseurl}reports/delayedShipmentsList`;
export const getOnTrackShipmentsCount = `${reportbaseurl}reports/ontrackShipments`;
export const getOnTrackShipmentsCountList = `${reportbaseurl}reports/ontrackShipmentsList`;

//Fibers
export const getDyedFiberList = `${baseurl}fibre/listdyedfibres`;
export const getFiberList = `${baseurl}fibre/list`;

export const dyedBOMRetry = `${baseurl}dyed/material/bom/retry/`;
export const dyedRoutingRetry = `${baseurl}dyed/material/routing/retry/`;
export const dyedClassificationRetry = `${baseurl}dyed/material/classification/retry/`;
export const dyedMaterialCreationRetry = `${baseurl}dyed/material/creation/retry/`;

//Charts Api

//vP
export const getVPAgainstVehicleId = `${baseurl}vp/getVPagainstVehicleID`;

//api after raise requirement
export const createContractOrder = `${baseurl}contracts/createorder`; //getstyleelements/style_id

//Labdip
export const getLabdipList = `${baseurl}labdip/list/`; // ${baseurl}labdip/list/id pass brand_id as params
export const addLabdip = `${sapbaseurl}sap/createlabdip`;
export const approveLabdip = `${sapbaseurl}sap/approvelabdip`; //POST'{ "labdip_id" : 200000594, "approved_version" : "A" }'
export const getLabdipDetail = `${baseurl}labdip/get`; ///labdip/get/200000599

//Greige create
//Below APi works also for  variant creation
export const createGreige = `${sapbaseurl}sap/greigecreate`;
export const createDyedFabric = `${sapbaseurl}sap/dyedcreation`;
export const getDyedProcessLoss = `${baseurl}extras/dyedprocessloss`; //shade_category, mechanical_finish, chemical_finish, fabric_type
export const getDyedFabricList = `${baseurl}dyed/list`;

//Yarn Library
export const getYarnLibrary = `${baseurl}yarns/library`; // params:{yarn_blend:yarn_blend} etc
export const getGreigeYarnList = `${baseurl}extras/greige/`; // extras/greige/1600005741

//sap
export const createSO = `${sapbaseurl}sap/createso`;
export const getGenders = `${sapbaseurl}sap/genders`;
export const getPlants = `${sapbaseurl}sap/plants`;
export const getCCR = `${sapbaseurl}sap/ccr`;
export const getMechanicalFinish = `${sapbaseurl}sap/getmechanicalfinishmasters`;
export const getChemicalFinish = `${sapbaseurl}sap/getchemicalfinishmasters`; // api/mechanicalFinishCode
export const getElements = `${sapbaseurl}sap/getelementmaster`;
export const getFabricList = `${sapbaseurl}sap/fabric`;

//Contracts
export const getContractList = `${baseurl}contracts/list`;
export const getContractNums = `${sapbaseurl}sap/getcustomerpr`;
export const getContractDetail = `${baseurl}contracts/get`; // /get/contractId

//Yarn Apis
export const getYarnCountDD = `${baseurl}yarns/counts`;
export const getYarnTechDD = `${baseurl}yarns/technologies`;
export const getYarnShadeTypeDD = `${baseurl}yarns/shade_types`;
export const getYarnCottonTypeDD = `${baseurl}yarns/cotton_types`;
export const getYarnCottonTreatmentDD = `${baseurl}yarns/cotton_treatment_effect`;
export const getYarnMelangeCodeDD = `${baseurl}yarns/melange_codes`;
export const getYarnQualitiesDD = `${baseurl}yarns/qualities`;
export const getYarnPropertiesDD = `${baseurl}yarns/properties`;
export const getYarnSpecialPropertiesDD = `${baseurl}yarns/special_properties`;

//PO Life cycle
export const getPOLifeCycle = `${baseurl}cases/polifecycle`; // data-raw '{ "po_caseid" : "241" }'
export const revertBooking = `${baseurl}cases/revertBooking`; // data-raw '{  "booking_id" : "759" }'

//PO
export const poMoveBack = `${baseurl}purchaseorders/moveback`; //purchaseorders/moveback/3950 (PO_STYLE_ID)
export const getPOList = `${baseurl}purchaseorders/list`; //Can pass buyers also in params
export const getPOStyles = `${baseurl}purchaseorders/postyles`; // ${baseurl}styles/postyles/po_id
export const getPOStyleShade = `${baseurl}purchaseorders/postyleshades`; // ${baseurl}styles/postyleshades/po_style_id
export const getPOStyleShadeSize = `${baseurl}purchaseorders/postyleshadesize`; //${baseurl}styles/postyleshades/shade_id
export const getCADData = `${baseurl}purchaseorders/getcaddata`; //po_id=526&style_id=2060020043
export const getStyleDetails = (style_id) =>
  `${baseurl}purchaseorders/getstyledetails/${style_id}`;
export const reopenStyle = (style_id) =>
  `${baseurl}purchaseorders/reopen/style/${style_id}`;

export const addCADData = `${baseurl}styles/submitcaddata`;
export const submitPMReview = `${baseurl}styles/submitpmreview`;
export const getCADListing = `${baseurl}styles/cadlist`;
export const getPMReviewListing = `${baseurl}styles/pmreviewlist`;

//Export PO List excels
export const exportmaterialbom = `${baseurl}greige/exportmaterialbom`; // "material_code" : "1600005953"
export const exportmaterialcode = `${baseurl}greige/exportmaterialcode`; //    "material_code" : "1600005963"
export const exportmaterialclass = `${baseurl}greige/exportmaterialclass`; //    "material_code" : "1600005963"
export const exportmaterialkbatch = `${baseurl}greige/exportmaterialkbatch`; //    "material_code" : "1600005963"

export const exportpostyles = `${baseurl}purchaseorders/exportpostyles`; //"po_id" : "2534"
export const exportposyedfabricbom = `${baseurl}purchaseorders/exportposyedfabricbom`; //"po_id" : "2534"
export const exportposyedfabric = `${baseurl}purchaseorders/exportposyedfabric`; //"po_id" : "2534"
export const exportpoaopphantom = `${baseurl}purchaseorders/exportpoaopphantom`; //    "po_id" : "2529"
export const exportpolabdipphantom = `${baseurl}purchaseorders/exportpolabdipphantom`; //    "po_id" : "2529"

//Planner
export const getPlannerListing = `${baseurl}styles/plannerlist`;
export const getPlannerData = `${baseurl}purchaseorders/getplannerdata`; //po_id=526&style_id=2060020043
export const submitPlannerData = `${baseurl}styles/submitplannerdata`; //po_id=526&style_id=2060020043

//Document Type
export const getDocumentTypes = `${baseurl}extras/documentTypes`;

//Reverse Auction
export const createReverseAuction = `${baseurl}ra/create_request`;
export const getReverseAuctionList = `${baseurl}ra/listReverseAuction`;
export const getReverseAuctionDetail = `${baseurl}ra/getRAdetails`;
export const getVendorFormDetail = `${baseurl}ra/vendorFormDetails`;
export const submitVendorBid = `${baseurl}ra/submitVendorBid`;
export const updateRADetails = `${baseurl}ra/updateRAdetails`;
export const awardVendor = `${baseurl}ra/awardVendor`;
export const getCurrency = `${baseurl}extras/currency`;
export const deleteReverseAuction = `${baseurl}ra/deleteReverseAuction`;
export const getDepartments = `${baseurl}extras/listdepartments`;

//ES PLBooking
export const getPODestinations = `${baseurl}cases/po_destination`;
export const getPLCasesList = `${baseurl}cases/getpos`;
export const getInvoicesList = `${baseurl}cases/getInvoices`;
export const getCasesVariableData = (appUID) =>
  `${baseurl}${inboxList}${appUID}/variables/`;
export const setCasesVariableData = (appUID, tasUID) =>
  `${baseurl}${inboxList}${appUID}/setvariables/${tasUID}`;

/* es */
export const getGreigeKbDropDowns = `${baseurl}greige/getkbdropdowns`;
export const getpolistforbooking = "cartons/po";
export const getbookinglist = "cartons/booking/list";
export const process =
  "process/19764807760b713e4b5d268050782044/task/15814876760b71802b81966033958845";

// Cases API'S
export const getPlCases = "cases/getpos?";
export const getPo_destination = "cases/po_destination";
export const getPo_shipmentmode = "cases/po_shipmentmode";
export const getCHAAgents = "cases/cha/agencies/users/";
export const listContainer = "cases/list_containers?";
export const addpldata = "cases/addpldata";
export const startCases = "cases/startcases";
export const submitProcessInput = "cases";
export const inboxList = "cases/";
export const donloadCaseDocs = "cases/cases_ShowDocument";
export const updateRTL = "cases/updateRTL";

//GSR
export const createGSR = `${baseurl}sampling/generategsr`;
export const getAllUsers = `${baseurl}users/dropdown`;
export const getDevelopementTypes = `${baseurl}sampling/development_types`;
export const getGSRDetail = `${baseurl}sampling/gsr/details`;
export const getGSRList = `${baseurl}sampling/listgsrs`;

export const getMaterialGroups = `${baseurl}extras/material_groups`;

export const getOrderTypes = `${baseurl}sampling/order_types`;
export const getPackingDetails = `${baseurl}packing/details`;
export const getPackingList = `${baseurl}packing/list`;
export const getSampleTypes = `${baseurl}sampling/sample_type`;

// Vehicle Planning
export const addVehicleLoad = `${baseurl}vp/vehicle_load`; //Post  "vehicle_id" : 35, "loaded_goods" : [ { "packing_list_item_barcode_id" : 72 } ]
export const getBookingListForVehicle = `${baseurl}cases/getbookings`;
export const listVehiclePlanning = `${baseurl}vp/listVehiclePlanning`;
export const getTransporterList = "vp/listVendors";
export const getLoadTypeList = "vp/listVehicleLoadTypes";
export const generateVehiclePlanning = "vp/planning";
export const createVehiclePlanning = "vp/sendTransporterRequest";
export const transporterFormDetails = "vp/transporterFormDetails";
export const transporterFeedback = "vp/transporterFeedback";
export const getTransporterVehicleDetail = "vp/getTransporterVehicleDetails";
export const awardTransporter = "vp/awardTransporter";
export const updateVPDetails = "vp/updateVPDetails";
export const warehouseUpdate = "vp/warehouseUpdate";
export const submitVehiclePlanningFeedback = "vp/submitVehiclePlanningFeedback";

// SAP API"S
export const createProductionOrder = `${sapbaseurl}sap/createproductionorder`;
export const createProductionorderWithTeco = `${sapbaseurl}sap/createproductionorderwithteco`;
export const createpurchaserequest = `${sapbaseurl}sap/createpurchaserequest`;
export const getWarehouseList = `${sapbaseurl}sap/plants`;
export const invoiceqrcode = `${sapbaseurl}sap/invoiceqrcode`;
// SAP Stock AND Invoice API"S
export const getSOOrderStock = `${sapbaseurl}sapsoinvoice/sostock`;
export const updatedelivery = `${sapbaseurl}sapsoinvoice/updatedelivery`;
export const deliverynote = `${sapbaseurl}sapsoinvoice/deliverynote`;
export const pgiupdate = `${sapbaseurl}sapsoinvoice/pgiupdate`;
export const createInvoice = `${sapbaseurl}sapsoinvoice/createInvoice`;
export const saplodingport = `${sapbaseurl}sapsoinvoice/saplodingport`;
export const sapdischargeport = `${sapbaseurl}sapsoinvoice/sapdischargeport`;
export const stockapprove = `${sapbaseurl}sapsoinvoice/stockapprove`;
export const stockrejections = `${sapbaseurl}sapsoinvoice/stockrejections`;
export const deliverycheck = `${sapbaseurl}sapsoinvoice/deliverycheck`;

export const loading_types = "cases/loading_types";
export const cha_agencies = "cases/cha/agencies";
export const listshipmentmodebasedonbuyer =
  "cases/listshipmentmodebasedonbuyer";
export const countries = "extras/countries";

export const getsonumber = "purchaseorders/getsonumber";
export const get_material_code = "yarns/get/";
export const sap_plants_list = `${sapbaseurl}sap/plants`;

export const getBondItem = "bond/getBondItem";
export const getformjson = "cases/getformjson";
export const bond_Invoice_list = "/bond/bondInvoiceList";
export const getStockstock = `${sapbaseurl}sap/getstock`;
export const generateurl = "docusign/generateurl";

export const claim_case = (app_uid, tas_uid) => {
  return `cases/${app_uid}/claim/${tas_uid}`;
};

// portal apis
export const getBookingType = "portal/bookingtype?brand_id=";
export const forwarders = "portal/forwarders";
export const exit_country_ports = "portal/exit_country_ports";
export const quantity_units = "portal/quantity_units";
export const package_units = "portal/package_units";
export const shippingmodes = "portal/shippingmodes";
export const portal_consignee = "portal/consignee";
export const portal_shippers = "portal/shippers";
export const portal_buyer_discharge_port = "portal/buyer_discharge_port";
export const portal_delivery_places = "portal/portal_delivery_places";
export const portal_partial_shipment = "portal/partial_shipment";
export const portal_product_types = "portal/product_types";
export const portal_genders = "portal/genders";
export const portal_construction = "portal/construction";
export const portal_special_products = "portal/special_products";
export const portal_port_of_loading = "portal/port_of_loading";
export const portal_fob_points = "portal/fob_points";
export const portal_damco_office = "portal/damco_office";
export const portal_forwarders = "portal/forwarders";
export const portal_incoterms = "portal/incoterms";
export const portal_consigneeportal = "portal/consigneeportal";
export const portal_consigneeportofdischarge =
  "portal/consigneeportofdischarge";
export const portal_consigneeplaceofdelivery =
  "portal/consigneeplaceofdelivery";

export const deleteVehiclePlanning = `vp/deleteVehiclePlanning`;
export const createGarmentSalesOrder = `${sapbaseurl}sap/creategarmentsalesorder`;
export const soRelease = `${sapbaseurl}sap/sorelease`;
export const getSoReference = `getSoReference`;
export const getSoNo = `getSoNo`;
export const getPrices = `cases/getprices`;
export const getDepartmentList = "extras/listdepartments";
export const appendGoogleSheetData = "extras/appendGoogleSheetData";

export const getPOCaseNo =
  "cases/getpocasenumberagainsttheplcasenumber?plCaseNumber=";

export const updateplfinalstatus = "cases/updateplfinalstatus";
export const listlistinvoicecases = `${baseurl}cases/list/listlistinvoicecases`;
export const getsapprice = `${sapbaseurl}sap/getsapprice`;

// Customers
export const getCustomersList = `${baseurl}customer`;
export const createCustomer = `${baseurl}customer`;
export const getCustomerDetails = (cid) => `${baseurl}customer/${cid}`;

export const getCustomerBrands = `${baseurl}brands`;
export const getCustomerSalesOrganizations = `${baseurl}customer/sales_organizations`;
export const getCustomerDistributionChannel = `${baseurl}customer/distribution_channel`;
export const getCustomerBusinessPartners = `${baseurl}customer/business_partners`;
export const getCustomerDivisions = `${baseurl}customer/divisions`;
export const getCustomerCompanies = `${baseurl}customer/companies`;
export const getCustomerReconAccounts = `${baseurl}customer/recon_accounts`;
export const getCustomerPaymentTerms = `${baseurl}customer/payment_terms`;
export const getCustomerGroups = `${baseurl}customer/groups`;
export const getCustomerAccountAssignments = `${baseurl}customer/account_assignments`;
export const getCustomerDeliveryPlants = `${baseurl}customer/delivery_plants`;
export const getCustomerCountries = `${baseurl}customer/countries`;
export const getCustomerCurrencies = `${baseurl}customer/currencies`;
export const getCustomerTitles = `${baseurl}customer/titles`;

export const getCustomerStates = (cid) =>
  `${baseurl}customer/country/${cid}/states`;
export const getCustomerCities = (cid, sid) =>
  `${baseurl}customer/country/${cid}/states/${sid}/cities`;
export const getCustomerStateCode = (sid) =>
  `${baseurl}customer/states/${sid}/tin`;

export const getCustomerPriceGroups = `${baseurl}customer/price_groups`;
export const getCustomerIncoterms = `${baseurl}customer/incoterms`;

// Yarn Labdip
export const getYarnLabdipList = `${baseurl}yarns/labdip`;
export const createYarnLabdip = `${baseurl}yarns/labdip`;
export const getYarnLabdipDetails = (id) => `${baseurl}yarns/labdip/${id}`;

export const getCottonTypeList = `${baseurl}yarns/cotton_types`;
export const getCottonTreatmentEffectList = `${baseurl}yarns/cotton_treatment_effect`;
export const getSpecialPropertyList = `${baseurl}yarns/special_properties`;
export const getTechnologiesList = `${baseurl}yarns/technologies`;
export const getMixingLabdipCodeList = `${baseurl}yarns/melange_codes`;
export const getSubstratesList = `${baseurl}yarns/substrates`;

export const createYarn = `${baseurl}yarns`;
export const getYarnDetails = (id) => `${baseurl}yarns/${id}`;
export const getYarnCount = `${baseurl}yarns/counts`;

//Stock Management
// Requests
export const getRequestList = `${baseurl}requests/list`;
export const getRequestNums = `${sapbaseurl}sap/getcustomerpr`;
export const getRequestDetail = `${baseurl}requests/get`;

//Stock
export const getStockList = `${baseurl}sampling/stock/list`;
export const getStockDetail = `${baseurl}contracts/get`;
export const createStock = `${baseurl}sampling/stock/create`;
export const editStock = (stock_id) =>
  `${baseurl}sampling/stock/edit/${stock_id}`;
export const getStockByMaterialId = (material_id) =>
  `${baseurl}sampling/material/stock/${material_id}`;

export const createStockRequest = `${baseurl}sampling/stock/transition`;
export const listStockRequest = `${baseurl}sampling/stock/transition/list`;
export const stockRequestDetails = (id) =>
  `${baseurl}sampling/stock/transition/${id}`;

export const listStockRelease = `${baseurl}sampling/stock/transition/list`;

/* Sampling */
/* yarn */
export const createSamplingYarn = `${baseurl}sampling/yarn`;
export const listSamplingYarn = `${baseurl}sampling/yarns`;
export const getSamplingYarnDetails = (id) => `${baseurl}sampling/yarn/${id}`;
export const updateSamplingYarnDetails = (id) =>
  `${baseurl}sampling/yarn/${id}`;

/* Greige */
export const createSamplingGreige = `${baseurl}sampling/greige`;
export const listSamplingGreige = `${baseurl}sampling/greiges`;
export const getSamplingGreigeDetails = (id) =>
  `${baseurl}sampling/greige/${id}`;
export const updateSamplingGreigeDetails = (id) =>
  `${baseurl}sampling/greige/${id}`;
export const getSamplingGreigeVarientDetails = (id) =>
  `${baseurl}sampling/greige/variant/${id}`;
/* Styling */
export const getSamplingStyleData = (inquiry_id, gsr_id) =>
  `${baseurl}sampling/gsr/styles/${inquiry_id}/${gsr_id}`;
export const getSamplingStyleElements = (inquiry_id, gsr_id) =>
  `${baseurl}sampling/gsr/styles/${inquiry_id}/${gsr_id}`;
export const getSamplingYarnShadeCode = `${baseurl}sampling/yarn/shade_codes/list`;
export const getSamplingGreigeVariantList = (greige_id, opTube) =>
  `${baseurl}sampling/greigevariant?generic_id=${greige_id}&opTube=${opTube}`;

/* Dyed */
export const listSamplingDyed = `${baseurl}sampling/dyed/list`;
export const samplingAddData = `${baseurl}sampling/style/adddata`; //FAbric Style app/fabric_style/2060010184

/*inquiries  */
export const getInquiryList = `${baseurl}sampling/inquiries`;
export const createInquiry = `${baseurl}sampling/inquiry`;

/* PD list */
export const getSamplingPDStyleElements = (gsr_id) =>
  `${baseurl}sampling/style/getstyleelements/${gsr_id}`; //getstyleelements/style_id
export const samplingAddPDData = `${baseurl}sampling/style/addpddata`;
export const getSamplingGSRHistory = (gsr_id) =>
  `${baseurl}sampling/gsr/po/${gsr_id}`;

/* Create & lis PO */
export const getSamplingListPO = `${baseurl}sampling/po/list`;
export const createSamplingPO = `${baseurl}sampling/po`;
export const getSamplingCompletedPO = `${baseurl}sampling/po`;
export const getSamplingCompletedPODetails = (poid) =>
  `${baseurl}sampling/po/${poid}`;
export const getSamplingCompletedPOIteration = (varientId) =>
  `${baseurl}sampling/po/iteration/${varientId}`;
export const createPOIteration = `${baseurl}sampling/po/greige/iteration`;

export const submitPOIteration = (po_id) => `${baseurl}sampling/po/${po_id}`;

export const getSamplingYarnPOIteration = (po_id) =>
  `${baseurl}sampling/po/iteration/${po_id}`;
export const addYarnPOIteraion = `${baseurl}sampling/po/yarn/iteration`;
export const getYarnBatchByID = (id) =>
  `${baseurl}sampling/material/stock/${id}`;

export const getSamplingDyedGreige = (id) =>
  `${baseurl}sampling/dyed/greige/${id}`;
export const addDyedPOIteraion = `${baseurl}sampling/po/dyed/iteration`;
export const submitGarmentDetails = (po_id) =>
  `${baseurl}/sampling/po/garment/${po_id}`;
