import React from "react";
import _ from "lodash";
import {
  Font,
  Document,
  Page,
  PDFViewer,
  Image,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import SummaryItemsTable from "./SummaryItemsTable";
import {
  plGetSummaryData,
  plGetUniqueSizes,
} from "../PackingListSummaryDataGenerator";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    flexWrap: "wrap",
    border: 1,
    borderColor: "black",
    padding: 10,
  },
  pageNumber: {
    padding: "10pt",
    width: "100%",
    fontSize: 8,
    textAlign: "center",
    border: 1,
  },
  heading: {
    fontSize: "12pt",
    fontFamily: "Times-Bold",
    width: "100%",
    textAlign: "center",
    fontWeight: "700",
    border: 1,
  },
  row: {
    flexDirection: "row",
    backgroundColor: "white",
    textAlign: "left",
    fontWeight: "600",
    fontSize: 8,
    margin: 5,
  },
  column: {
    width: "15%",
    fontFamily: "Times-Bold",
  },
});
Font.register({
  family: "Times-Bold",
});
const hyphenationCallback = (word) => {
  return [...word];
};
Font.registerHyphenationCallback(hyphenationCallback);

const GenerateMergedSummary = ({ data, brandName }) => {
  return (
    // <div
    //   style={{
    //     width: "100vw",
    //     height: "100vh",
    //   }}
    // >
    //   <PDFViewer
    //     style={{
    //       width: "100vw",
    //       height: "100vh",
    //     }}
    //   >
    <Document>
      <Page size="A4" style={styles.page} orientation={"landscape"}>
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: 0.5,
            margin: "0 auto",
            width: "95%",
            borderStyle: "dashed",
          }}
        >
          <Image
            src="/assets/media/logos/logo1.png"
            style={{ maxHeight: 60, padding: 5 }}
          />
        </View>
        <View
          style={{
            marginVertical: 20,
          }}
        >
          <SummaryItemsTable
            SummaryData={plGetSummaryData(data)}
            brandName={brandName}
            uniqueSizes={plGetUniqueSizes(data)}
          />
        </View>
      </Page>
    </Document>
    //   </PDFViewer>
    // </div>
  );
};

export default GenerateMergedSummary;
