import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import config from "./SummaryTableConfig";
import _ from "lodash";

const UNIQUE_SIZE_WIDTH = 40;

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: config.rowBackgroundColor,
    borderColor: config.rowBorderColor,
    fontStyle: "bold",
    fontSize: config.rowFontSize,
  },
  rowItem: {
    fontFamily: "Times-Bold",
    fontSize: 7,
    height: "100%",
    //alignItems: "center",
    width: "10%",
    borderRight: 1,
    textAlign: "center",
    alignItems: "center",
    padding: 2,
  },
});

const SummaryTableRow = ({ items, uniqueSizes }) => {
  const rows = items.map((item, index) => {
    return item["FIELDS"].map((field, fieldIndex) => {
      let show = fieldIndex === Math.floor((item["FIELDS"].length - 1) / 2);
      let lastIndex = fieldIndex === item["FIELDS"].length - 1;
      return (
        <View style={styles.row} key={fieldIndex} wrap={false}>
          <Text
            style={{
              ...styles.rowItem,
              borderBottom: lastIndex ? 1 : 0,
              width: "10%",
              fontFamily: "Times-Bold",
            }}
          >
            {show && item["PO NO"]}
          </Text>
          <Text
            style={{
              ...styles.rowItem,
              borderBottom: lastIndex ? 1 : 0,
              width: "10%",
              fontFamily: "Times-Bold",
            }}
          >
            {show && item["STYLE NO"]}
          </Text>
          <Text
            style={{
              ...styles.rowItem,
              borderBottom: lastIndex ? 1 : 0,
              fontFamily: "Times-Bold",
              fontSize: 7,
              width: "10%",
            }}
          >
            {show && item["SHADE"]}
          </Text>
          <Text
            style={{
              ...styles.rowItem,
              borderBottom: 1,
              width: `10%`,
              fontSize: 7,
            }}
          >
            {field}
          </Text>
          {_.map(item["FIELD_VALUES"], (value, key) => {
            if (key === "totalCtns") {
              let show =
                fieldIndex === Math.floor((item["FIELDS"].length - 1) / 2);
              return (
                <Text
                  key={key}
                  style={{
                    ...styles.rowItem,
                    borderBottom: Number(lastIndex),
                    paddingTop: 10,
                    width: "10%",
                    fontSize: 7,
                  }}
                >
                  {show && Number(value[fieldIndex])}
                </Text>
              );
            }

            if (["totalPacks", "totalPcs", "totalCtns"].includes(key)) {
              return (
                <Text
                  key={key}
                  style={{
                    ...styles.rowItem,
                    borderBottom: 1,
                    width: "10%",
                    fontSize: 7,
                  }}
                >
                  {lastIndex
                    ? (_.isNaN(Number(value[fieldIndex]))
                        ? 0
                        : Number(value[fieldIndex])) + "%"
                    : Number(value[fieldIndex])}
                </Text>
              );
            } else {
              return (
                <Text
                  key={key}
                  style={{
                    ...styles.rowItem,
                    fontSize: 7,
                    borderBottom: 1,
                    width: `${UNIQUE_SIZE_WIDTH / uniqueSizes.length}%`,
                  }}
                >
                  {lastIndex
                    ? (_.isNaN(Number(value[fieldIndex]))
                        ? 0
                        : Number(value[fieldIndex])) + "%"
                    : Number(value[fieldIndex])}
                </Text>
              );
            }
          })}
        </View>
      );
    });
  });
  return <Fragment>{rows}</Fragment>;
};

export default SummaryTableRow;
