import { Document, Text, Page, StyleSheet, View } from "@react-pdf/renderer";
import CompanyAddress from "../../gatePass/deliveryChallan/CompanyAddress";
import Logo from "../../gatePass/manualGatePass/Logo";
import { esMargin } from "../esReactPdfStyles";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 10,
    border: "1 solid black",
    fontFamily: "Helvetica",
  },
  companyInfo: {
    display: "flex",
    flexDirection: "row",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  title: {
    fontSize: 9,
    textAlign: "center",
    padding: 3,
    borderBottom: "1 solid black",
  },
  logo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    border: "1pt solid black",
    borderTop: 0,
    borderLeft: 0,
    textAlign: "center",
  },
  mainContainer: {
    fontFamily: "Helvetica",
  },
  label: {
    fontSize: 8,
  },
  value: {
    fontSize: 7,
  },
  esTd: {
    fontSize: 8,
    border: "1pt solid black",
    borderLeft: 0,
    borderTop: 0,
    padding: 3,
  },
  thead: {
    fontSize: 8,
    border: "1pt solid black",
    borderLeft: 0,
    borderTop: 0,
    padding: 3,
    fontFamily: "Helvetica-Bold",
  },
  tableContainer: {},
  ...esMargin,
});

const data = {
  for_Account_of: `M/S TU Clothing c/o DHL
TU Square,North View
Walsgrave Triangle
Coventry CV2 2SJ
United Kingdom`,
  invoice_no: "411006751",
  invoice_date: "19-08-2021",
  payment_terms: "TT- 30 DAYS",
  port_of_loading: "NHAVA SHEVA, INDIA",
  destination: "UNITED KINGDOM",
  country_of_origin: "INDIA",
  pre_carriage_by: "eddysapata",
  ocean_vessel: "eddysapata ocean",
  sailing_on_abt: "eddysapata sailing",
};

// Create Document Component
const LamourInvoice = () => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.mainContainer}>
          <Text style={styles.title}>COMMERCIAL INVOICE</Text>
        </View>

        {/* Company Info Starts */}
        <View style={styles.companyInfo}>
          <View
            style={[
              {
                width: "60%",
                borderLeft: "1pt solid black",
                borderRight: "1pt solid black",
              },
            ]}
          >
            <CompanyAddress />
          </View>
          <View style={[{ width: "40%" }, styles.logo]}>
            <Logo style={{ maxWidth: 300 }} />
          </View>
        </View>
        {/* Company Info Ends */}

        {/* for account container starts */}
        <View style={styles.flexRow}>
          <View
            style={[
              {
                width: "60%",
                borderLeft: "1pt solid black",
                border: "1pt solid black",
                borderTop: 0,
                padding: 3,
              },
              styles.flexColumn,
            ]}
          >
            <Text style={styles.label}>For account of:</Text>
            <Text style={styles.value}>{data?.for_Account_of}</Text>
          </View>
          <View
            style={[
              {
                width: "40%",
                border: "1pt solid black",
                borderTop: 0,
                borderLeft: 0,
                padding: 3,
              },
              styles.flexColumn,
            ]}
          >
            <View style={styles.flexRow}>
              <Text style={styles.label}>Invoice No:</Text>
              <Text style={styles.value}>{data?.invoice_no}</Text>
            </View>
            <View style={styles.flexRow}>
              <Text style={styles.label}>Invoice Date:</Text>
              <Text style={styles.value}>{data?.invoice_date}</Text>
            </View>
            <View style={styles.flexRow}>
              <Text style={styles.label}>Payment Terms:</Text>
              <Text style={styles.value}>{data?.payment_terms}</Text>
            </View>
          </View>
        </View>
        {/* for account container ends */}

        {/* port of loading starts */}
        <View style={[styles.flexRow]}>
          <View
            style={[
              styles.flexRow,
              {
                width: "60%",
                border: "1pt solid black",
                borderTop: 0,
              },
            ]}
          >
            <View style={[styles.flexColumn, { width: "50%", padding: 3 }]}>
              <Text style={styles.label}>Port of Loading:</Text>
              <Text style={[styles.value]}>{data?.loading_port}</Text>
            </View>
            <View style={[styles.flexColumn, { width: "50%", padding: 3 }]}>
              <Text style={styles.label}>Destination:</Text>
              <Text style={styles.value}>{data?.destination}</Text>
            </View>
          </View>
          <View
            style={[
              {
                width: "40%",
                border: "1 solid black",
                borderTop: 0,
                borderLeft: 0,
              },
            ]}
          >
            <View style={[styles.flexColumn, { padding: 3 }]}>
              <Text style={[styles.label]}>Country of Origin:</Text>
              <Text style={styles.value}>{data?.country_of_origin}</Text>
            </View>
          </View>
        </View>
        {/* port of loading ends */}

        {/* pre carriage starts */}
        <View style={[styles.flexRow]}>
          <View
            style={[
              styles.flexRow,
              {
                width: "60%",
                border: "1pt solid black",
                borderTop: 0,
              },
            ]}
          >
            <View style={[styles.flexColumn, { width: "50%", padding: 3 }]}>
              <Text style={styles.label}>Pre Carriage By:</Text>
              <Text style={styles.value}>{data?.pre_carriage_by}</Text>
            </View>
            <View style={[styles.flexColumn, { width: "50%", padding: 3 }]}>
              <Text style={styles.label}>Ocean Vessel:</Text>
              <Text style={styles.value}>{data?.ocean_vessel}</Text>
            </View>
          </View>
          <View
            style={[
              {
                width: "40%",
                border: "1 solid black",
                borderTop: 0,
                borderLeft: 0,
              },
            ]}
          >
            <View style={[styles.flexColumn, { padding: 3 }]}>
              <Text style={styles.label}>Sailing on/abt:</Text>
              <Text style={styles.value}>{data?.sailing_on_abt}</Text>
            </View>
          </View>
        </View>
        {/* pre carriage ends */}

        {/* Table starts */}
        <View style={styles.tableContainer}>
          <View style={styles.flexRow}>
            <View
              style={[
                styles.thead,
                { width: "25%", borderLeft: "1 solid black" },
              ]}
            >
              <Text>Marks And Number</Text>
            </View>
            <View style={[styles.thead, { width: "50%" }]}>
              <Text>Description</Text>
            </View>
            <View style={[styles.thead, { width: "25%" }]}>
              <Text>TOTAL UNITS</Text>
            </View>
            <View style={[styles.thead, { width: "25%" }]}>
              <Text>Unit Price(USD/Pcs)</Text>
            </View>
            <View style={[styles.thead, { width: "25%" }]}>
              <Text>Amount(USD)</Text>
            </View>
          </View>
          <View style={[styles.esTbody, styles.flexRow]}>
            <View
              style={[
                styles.esTd,
                { width: "25%", borderLeft: "1 solid black" },
              ]}
            >
              <Text>As Per Attachment ( Packing List )</Text>
            </View>
            <View style={[styles.esTd, { width: "50%" }]}>
              <Text style={[styles.mb_2]}>MENS THERMAL LONG JOHN (PANT)</Text>
              <Text>
                50% BCI COTTON 25% RECYCLED POLYESTER 25 % FSC VISCOSE KNITTED
                DYED
              </Text>
              <View style={styles.flexColumn}>
                <View style={styles.flexRow}>
                  <Text style={[styles.label, { width: "50%" }]}>
                    CUSTOMER ORDER NO:
                  </Text>
                  <Text style={[styles.value, { width: "50%" }]}>2422305</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={[styles.label, { width: "50%" }]}>
                    (OUR) ORDER NO:
                  </Text>
                  <Text style={[styles.value, { width: "50%" }]}>405477</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={[styles.label, { width: "50%" }]}>
                    PI ORDER NO:
                  </Text>
                  <Text style={[styles.value, { width: "50%" }]}>-</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={[styles.label, { width: "50%" }]}>
                    PATTERN/STYLE:
                  </Text>
                  <Text style={[styles.value, { width: "50%" }]}>-</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={[styles.label, { width: "50%" }]}>COLOR:</Text>
                  <Text style={[styles.value, { width: "50%" }]}>-</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={[styles.label, { width: "50%" }]}>
                    SAINSBURY SUPPLIER NO.:
                  </Text>
                  <Text style={[styles.value, { width: "50%" }]}>120309</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={styles.label}>
                    SAINSBURY DISTRIBUTION CENTER NO :-
                  </Text>
                  <Text style={styles.value}>-</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={[styles.label, { width: "50%" }]}>
                    COMMODITY CODE:-
                  </Text>
                  <Text style={[styles.value, { width: "50%" }]}>61034990</Text>
                </View>
              </View>
            </View>
            <View style={[styles.esTd, { width: "25%" }]}>
              <Text>4002 (60 CTN)</Text>
            </View>
            <View style={[styles.esTd, { width: "25%" }]}>
              <Text>Unit Price(USD/Pcs)</Text>
            </View>
            <View style={[styles.esTd, { width: "25%" }]}>
              <Text>Amount(USD)</Text>
            </View>
          </View>
        </View>
        {/* Table ends */}
      </Page>
    </Document>
  );
};
export default LamourInvoice;
