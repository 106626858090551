import { Image } from "@react-pdf/renderer";

const Logo = () => {
  return (
    <Image
      src="/assets/media/logos/logo1.png"
      style={{ maxHeight: 60, padding: 5 }}
    />
  );
};

export default Logo;
