import { Text, Image, StyleSheet, View } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  title: {
    fontSize: 9,
    fontWeight: "bold",
    marginBottom: "5pt",
  },
  desc: {
    fontSize: 8,
    marginBottom: "5pt",
  },
});

const CompanyAddress = () => {
  return (
    <View style={{ padding: "5pt", borderBottom: "1pt solid black" }}>
      <Text style={{ ...styles.title, marginBottom: 2 }} break>
        Pratibha Syntex Limited
      </Text>
      <Text style={styles.title} break>
        ( Govt. Recognized Three Star Export House )
      </Text>
      <Text style={styles.desc}>Plot No 4 , Industrial Growth Centre,</Text>
      <Text style={styles.desc}>
        Kheda Pithampur - 454774 , Distt. Dhar (MP) INDIA
      </Text>
      <Text style={styles.desc}>Phone : 7292-404362 Fax : 7292-256341</Text>
    </View>
  );
};

export default CompanyAddress;
