import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import { getBuyers } from "../../apis/networks";

export const fetchBuyers = createAsyncThunk(
  "buyers/fetchBuyers",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(getBuyers);
      return await response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const buyersSlice = createSlice({
  name: "buyers",
  initialState: {
    buyers: [],
    loading: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBuyers.pending, (state) => {
      state.buyers = {};
      state.loading = true;
    });
    builder.addCase(fetchBuyers.fulfilled, (state, { payload }) => {
      state.buyers = payload;
      state.loading = false;
    });
    builder.addCase(fetchBuyers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const selectBuyers = createSelector(
  (state) => ({
    buyers: state.buyers,
    loading: state.buyers.loading,
    error: state.buyers.error,
  }),
  (state) => state
);

export default buyersSlice.reducer;
