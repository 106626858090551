import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseurl, profile } from "../../apis/networks";

export const fetchProfile = createAsyncThunk(
  "profile/fetchProfile",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${baseurl}${profile}`);
      return await response.data.user;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    loggedInUser: {},
    loading: "idle",
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProfile.pending, (state) => {
      state.loggedInUser = {};
      state.loading = "loading";
    });
    builder.addCase(fetchProfile.fulfilled, (state, { payload }) => {
      state.loggedInUser = payload;
      state.loading = "loaded";
    });
    builder.addCase(fetchProfile.rejected, (state, action) => {
      state.loading = "error";
      state.error = action.error.message;
    });
  },
});

export const selectProfile = createSelector(
  (state) => ({
    loggedInUser: state.profile,
    loading: state.profile.loading,
  }),
  (state) => state
);

export default profileSlice.reducer;
