//Reload page after submit
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import { Base64 } from "base64-string";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useLocation } from "react-router-dom";
import RSelect from "react-select";
import { toast } from "react-toastify";
import {
  getCurrency,
  getVendorFormDetail,
  submitVendorBid,
} from "../../apis/networks";
import Colors from "../../values/colors";
import ExpiryTimer from "./ExpiryTimer";

const esStyles = (theme) => ({
  cardContainer: {
    minHeight: "500px",
  },
  errorText: {
    marginTop: "5px",
    marginLeft: "5px",
    color: "#bf1650",
    "&::before": {
      display: "inline",
      content: '"⚠ "',
    },
  },
});

const gridColumns = [
  { id: "item", label: "Item" },
  { id: "desc", label: "Desc" },
  { id: "ra_currency", label: "Currency" },
  { id: "max_allowed_price", label: "Max Allowed Price" },
  { id: "vendor_currency", label: "Vendor Currency" },
  { id: "vendor_bid", label: "Your Bid" },
  { id: "price_inr", label: "Price in INR" },
];

const createGridData = (
  item,
  desc,
  currency,
  max_allowed_price,
  vendor_bid
) => {
  return {
    item,
    desc,
    currency,
    max_allowed_price,
    vendor_bid,
  };
};

/*
  from=> key=8D4h6zTD178mTESu&vp=213&tr=1001001
  to=> {key: "8D4h6zTD178mTESu", vp: "213", tr: "1001001"}
*/
const strToObj = (str) => {
  str = str.split("&");
  let obj = {};
  str.forEach((item) => {
    let _item = item.split("=");
    obj = {
      ...obj,
      [_item[0]]: _item[1],
    };
  });
  return obj;
};

const GRID_ITEM_NAME = "item_details";

const Row = (props) => {
  let {
    register,
    control,
    currencyOptions,
    setCurrentTotalPrice,
    isLast,
    setValue,
    gridItem,
    gridItemIdx,
    isBidExpired,
  } = props;

  let watchGrid = useWatch({
    control,
    name: `${GRID_ITEM_NAME}`,
    defaultValue: [
      {
        ra_currency: "",
        ra_item_id: "",
        item: "",
        description: "",
        vendor_currency: "",
        max_allowed_price: "",
        vendor_bid: "",
        price_inr: 0,
      },
    ],
  });

  useEffect(() => {
    let totalPrize = 0;
    watchGrid.forEach((item) => {
      totalPrize += Number(item.vendor_bid) ?? 0;
    });
    setCurrentTotalPrice(totalPrize);
  }, [watchGrid, setCurrentTotalPrice]);

  let watchedYourBid = useWatch({
    control,
    name: `${GRID_ITEM_NAME}.${gridItemIdx}.vendor_bid`,
    defaultValue: 0,
  });
  let watchedCurrency = useWatch({
    control,
    name: `${GRID_ITEM_NAME}.${gridItemIdx}.vendor_currency`,
    defaultValue: {},
  });

  useEffect(() => {
    let selectedCurrency = watchedCurrency;
    setValue(
      `${GRID_ITEM_NAME}.${gridItemIdx}.price_inr`,
      (watchedYourBid * selectedCurrency?.exchange_rate).toFixed(2)
    );
  }, [
    watchedYourBid,
    watchedCurrency,
    currencyOptions,
    gridItem,
    setValue,
    gridItemIdx,
  ]);

  return (
    <tr key={gridItemIdx}>
      <td>
        <input
          type="hidden"
          {...register(`${GRID_ITEM_NAME}.${gridItemIdx}.ra_item_id`)}
          className="form-control form-control-md"
        />
        <input
          type="text"
          {...register(`${GRID_ITEM_NAME}.${gridItemIdx}.item`)}
          disabled={!isLast || isBidExpired}
          className="form-control form-control-md"
        />
      </td>
      <td>
        <textarea
          type="text"
          disabled={isBidExpired}
          {...register(`${GRID_ITEM_NAME}.${gridItemIdx}.description`)}
          className="form-control form-control-md"
        />
      </td>
      <td>
        <input
          type="text"
          disabled
          {...register(`${GRID_ITEM_NAME}.${gridItemIdx}.ra_currency`)}
          className="form-control form-control-md"
        />
      </td>
      <td>
        <input
          type="text"
          disabled
          {...register(`${GRID_ITEM_NAME}.${gridItemIdx}.max_allowed_price`)}
          className="form-control form-control-md"
        />
      </td>
      <td style={{ minWidth: "190px" }}>
        <Controller
          name={`${GRID_ITEM_NAME}.${gridItemIdx}.vendor_currency`}
          control={control}
          render={({ field }) => (
            <RSelect
              {...field}
              required
              isDisabled={isBidExpired}
              menuPortalTarget={document.body}
              //value={_.find(currencyOptions, ["value", gridItem.vendor_currency])}
              onChange={(valObj) => {
                field.onChange(valObj);
              }}
              options={currencyOptions}
            />
          )}
        />
      </td>
      <td>
        <input
          type="number"
          {...register(`${GRID_ITEM_NAME}.${gridItemIdx}.vendor_bid`)}
          className="form-control form-control-md"
          disabled={isBidExpired}
        />
      </td>
      <td>
        <input
          type="number"
          disabled
          {...register(`${GRID_ITEM_NAME}.${gridItemIdx}.price_inr`)}
          className="form-control form-control-md"
        />
      </td>
    </tr>
  );
};

const GetVendorBid = ({ classes }) => {
  const location = useLocation();
  const [urlParams, setUrlParams] = useState({
    key: "",
    ra: "",
    vendor: "",
  });
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [expiryDateTime, setExpiryDateTime] = useState("");
  const [isBidExpired, setIsBidExpired] = useState(false);

  const [initialTotalPrice, setInitialTotalPrice] = useState(0);
  const [currentTotalPrice, setCurrentTotalPrice] = useState(0);

  // Below is the check to know
  const [isExistingBid, setIsExistingBid] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      status: "",
      event_date: "",
      ra_remarks: "",
      rank: "",
      [GRID_ITEM_NAME]: [
        {
          ra_currency: "",
          ra_item_id: "",
          item: "",
          description: "",
          vendor_currency: "",
          max_allowed_price: "",
          vendor_bid: "",
          price_inr: 0,
        },
      ],
    },
  });

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const { fields: gridItemFields } = useFieldArray({
    control,
    name: GRID_ITEM_NAME,
  });

  useEffect(() => {
    axios
      .get(getCurrency)
      .then((res) => {
        let currencies = res.data.map((item) => {
          return {
            label: item.name,
            value: item.code,
            exchange_rate: item.exchange_rate,
          };
        });
        setCurrencyOptions(currencies);
      })
      .catch((err) => {
        console.log("err");
      });
  }, []);

  const fetchVendorFormDetail = useCallback(
    (data, isTimer = false) => {
      axios
        .get(getVendorFormDetail, {
          params: data,
        })
        .then((res) => {
          let _formatedDate = moment(res.data?.event_date).format(
            "DD-MMM-YYYY"
          );
          let formatedExpiry = moment(res.data.expiry).format(
            "DD-MMM-YYYY, h:mm:ss a"
          );

          let totalPrice = 0;
          let _itemsDetails = res.data?.item_details?.map((item) => {
            let _item = { ...item };
            _item.vendor_currency = _.find(currencyOptions, [
              "value",
              _item.vendor_currency ?? "INR",
            ]);
            totalPrice += Number(_item.vendor_bid);
            return _item;
          });

          setInitialTotalPrice(totalPrice);
          if (totalPrice > 0) {
            setIsExistingBid(true);
          }

          if (isTimer) {
            setValue("rank", res.data.rank);
          } else {
            console.log("res.data", res.data);
            reset({
              ...res.data,
              // !Need to test  why ?>>
              /* vendor_currency: _.find(currencyOptions, [
                "value",
                res.data?.vendor_currency,
              ]), */
              expiry: formatedExpiry,
              event_date: _formatedDate,
              item_details: _itemsDetails,
            });
            setExpiryDateTime(res.data?.expiry);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [reset, currencyOptions, setValue]
  );

  const startTimer = (tt) => {
    setInterval(() => {
      fetchVendorFormDetail(tt, true);
    }, 30000);
  };

  useEffect(() => {
    let pathname = location?.pathname;
    let searchParams = _.split(pathname, "/");
    let base64Code = _.last(searchParams);
    if (_.isEmpty(base64Code)) {
      alert("Link");
      return;
    }
    try {
      const enc = new Base64();
      const b64 = enc.decode(base64Code);
      let tt = strToObj(b64);
      if (!_.isEmpty(tt)) {
        fetchVendorFormDetail(tt);
        startTimer(tt);
        setUrlParams(tt);
      }
    } catch (error) {
      toast.error("Something Wrong With the link", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [location, fetchVendorFormDetail]);

  const onSubmit = (data) => {
    setIsFormSubmitting(true);

    /* console.log("currentTotalPrice", currentTotalPrice);
    console.log("initialTotalPrice", initialTotalPrice);
    console.log("delta", getValues(`delta`)); */

    if (
      !(
        currentTotalPrice <= initialTotalPrice - getValues(`delta`) ||
        currentTotalPrice >= initialTotalPrice
      ) &&
      isExistingBid
    ) {
      toast.error(
        `The change in bid should be a minimum of  ${getValues("delta")}`,
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setIsFormSubmitting(false);
      return;
    }

    let finalDataToSubmit = {};
    let items = data.item_details.map((item) => {
      let _item = { ...item };
      _item.vendor_currency = _item.vendor_currency?.value ?? "";
      return _item;
    });
    finalDataToSubmit = {
      ...urlParams,
      item_details: items,
      vendor_remarks: data.vendor_remarks,
    };

    axios
      .post(submitVendorBid, finalDataToSubmit)
      .then((res) => {
        setIsFormSubmitting(false);
        toast.success(res.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        window.location.replace("https://pratibhasyntex.com/");
      })
      .catch((err) => {
        toast.error(_.capitalize(err.response.data.message), {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setIsFormSubmitting(false);
      });
  };

  return (
    <>
      <Box
        style={{
          width: "90%",
          margin: "auto",
          marginTop: "30px",
        }}
      >
        <Card>
          <CardContent>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={4}>
                <a href="#/" className="ml-10 mylogo">
                  <img alt="Logo" src="/assets/media/logos/logo1.png" />
                </a>
              </Grid>
              <Grid item xs={12} sm={4} className="text-end">
                {/* <h2 className="timer text-start">Vendor Bid</h2> */}
                <Typography variant="h5" color="initial">
                  Reverse Auction (
                  {!_.isNil(urlParams.ra) && `RA-${urlParams?.ra}`})
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} className="text-right">
                <ExpiryTimer
                  expiryDateTime={expiryDateTime}
                  onExpiry={() => {
                    setIsBidExpired(true);
                  }}
                />
              </Grid>
            </Grid>
            {/* <div style={{ height: "80px", background: "white", width: "100%" }}> */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Divider className="mt-5 mb-2" />
              <Grid container className="mt-5" spacing={2}>
                <Grid item xs={12} sm={4}>
                  <div className="text-nowrap">
                    <label htmlFor="title" className="mr-2">
                      Title
                    </label>
                    <input
                      disabled
                      type="text"
                      id="title"
                      {...register(`title`)}
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className="text-nowrap">
                    <label htmlFor="Status" className="mr-2">
                      Status
                    </label>
                    <input
                      disabled
                      type="text"
                      id="status"
                      {...register(`status`)}
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className="text-nowrap">
                    <label htmlFor="event_date" className="mr-2">
                      Event Date
                    </label>
                    <input
                      disabled
                      type="text"
                      id="event_date"
                      {...register(`event_date`)}
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className="text-nowrap">
                    <label htmlFor="ra_remarks" className="mr-2">
                      Remarks
                    </label>
                    <textarea
                      disabled
                      rows={2}
                      type="text"
                      id="ra_remarks"
                      {...register(`ra_remarks`)}
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className="text-nowrap">
                    <label htmlFor="rank" className="mr-2">
                      Rank
                    </label>
                    <input
                      disabled
                      type="text"
                      id="rank"
                      {...register(`rank`)}
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className="text-nowrap">
                    <label htmlFor="expiry" className="mr-2">
                      Expiry
                    </label>
                    <input
                      disabled
                      type="text"
                      id="expiry"
                      {...register(`expiry`)}
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className="text-nowrap">
                    <label htmlFor="lowest_quoted_price" className="mr-2">
                      Lowest Quoted Price
                    </label>
                    <input
                      disabled
                      type="text"
                      id="lowest_quoted_price"
                      {...register(`lowest_quoted_price`)}
                      className="form-control"
                    />
                  </div>
                </Grid>
              </Grid>

              {/* Grid */}
              <table className="table table-sm table-bordered mt-4">
                <thead
                  style={{
                    backgroundColor: Colors.appPrimaryBlue,
                    color: "#fff",
                  }}
                >
                  <tr>
                    {gridColumns.map((column, columnIdx) => {
                      return (
                        <th key={columnIdx} className="text-center p-1">
                          {column.label}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {gridItemFields.map((gridItem, gridItemIdx) => {
                    let isLast = false;
                    if (gridItemFields.length === gridItemIdx + 1) {
                      isLast = true;
                    }
                    return (
                      <Row
                        key={gridItemIdx}
                        setCurrentTotalPrice={setCurrentTotalPrice}
                        {...{
                          register,
                          control,
                          isBidExpired,
                          gridItemFields,
                          currencyOptions,
                          isLast,
                          gridItem,
                          gridItemIdx,
                          setValue,
                          errors,
                          classes,
                        }}
                      />
                    );
                  })}
                </tbody>
              </table>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <div>
                    <label htmlFor="vendor_remarks">Vendor Remarks</label>
                    <textarea
                      rows={2}
                      type="text"
                      id="vendor_remarks"
                      disabled={isBidExpired}
                      {...register(`vendor_remarks`)}
                      className="form-control"
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                  className={clsx(isBidExpired && "d-none")}
                >
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isFormSubmitting}
                    endIcon={
                      isFormSubmitting ? (
                        <CircularProgress style={{ color: "#fff" }} size={20} />
                      ) : (
                        <SendIcon />
                      )
                    }
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
            {/* Grid */}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default withStyles(esStyles)(GetVendorBid);
