import { StyleSheet, Text, View } from "@react-pdf/renderer";
import config from "./SummaryTableConfig";
import SummaryTableHeader from "./SummaryTableHeader";
import SummaryTableRow from "./SummaryTableRow";
import SummaryTableFooter from "./SummaryTableFooter";
import { useEffect } from "react";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    borderColor: config.borderColor,
    fontSize: config.fontSize,
    border: 1,
    borderRight: 0,
    borderBottom: 0,
    // width: "95%",
    // margin: "0 auto",
  },
});

const SummaryItemsTable = ({ SummaryData, uniqueSizes, brandName }) => {
  return (
    <View
      style={{
        width: "95%",
        margin: "0 auto",
      }}
    >
      <View
        style={{
          borderColor: "black",
          alignItems: "center",
          padding: 5,
        }}
      >
        <Text
          style={{
            fontFamily: "Times-Bold",
            backgroundColor: "#eee",
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: 5,
            fontSize: 13,
          }}
        >
          {brandName}
        </Text>
      </View>
      <View style={styles.tableContainer}>
        <SummaryTableHeader uniqueSizes={uniqueSizes} />
        <SummaryTableRow items={SummaryData.items} uniqueSizes={uniqueSizes} />
        <SummaryTableFooter
          items={SummaryData.items}
          uniqueSizes={uniqueSizes}
        />
      </View>
    </View>
  );
};

export default SummaryItemsTable;
