import { configureStore } from "@reduxjs/toolkit";
import profileReducer from "./slices/profileSlice";
import buyersReducer from "./slices/buyersSlice";

export default configureStore({
  reducer: {
    profile: profileReducer,
    buyers: buyersReducer,
  },
});
