import { StyleSheet } from "@react-pdf/renderer";
import _ from "lodash";

// Create styles
const esTables = StyleSheet.create({});
const esUtility = StyleSheet.create({});

const generateLRTB = (name = "p", range = 3) => {
  let propName = name;

  switch (name) {
    case "p":
      propName = "padding";
      break;
    case "m":
      propName = "margin";
      break;
    case "bdr":
      propName = "border";
      break;
    default:
      propName = "p";
      break;
  }

  let allClasses = _.range(range).map((item, idx) => {
    return {
      [`${name}_${item}`]: { [`${propName}`]: item },
      [`${name}l_${item}`]: { [`${propName}-left`]: item },
      [`${name}r_${item}`]: { [`${propName}-right`]: item },
      [`${name}t_${item}`]: { [`${propName}-top`]: item },
      [`${name}b_${item}`]: { [`${propName}-bottom`]: item },
    };
  });

  return Object.assign({}, ...allClasses);
};

const esPadding = StyleSheet.create({
  ...generateLRTB("p", 6),
});

const esMargin = StyleSheet.create({
  ...generateLRTB("m", 6),
});

const esWidth = StyleSheet.create({
  w10p: { width: "10%" },
  w20p: { width: "20%" },
  w30p: { width: "30%" },
  w40p: { width: "40%" },
  w50p: { width: "50%" },
  w60p: { width: "60%" },
  w70p: { width: "70%" },
  w80p: { width: "80%" },
  w90p: { width: "90%" },
  w100p: { width: "100%" },
});

const esFlex = StyleSheet.create({
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
});

const esBdrWidth = StyleSheet.create({
  ...generateLRTB("bdr", 2),
});

const esBorder = StyleSheet.create({
  bdr1Black: { border: "1 solid black" },
  bdr2Black: { border: "2 solid black" },
  bdr1Red: { border: "2 solid red" },
  bdr2Red: { border: "2 solid red" },
});

const esFontFamily = StyleSheet.create({
  courier: { fontFamily: "Courier" },
  courierBold: { fontFamily: "Courier-Bold" },
  courierOblique: { fontFamily: "Courier-Oblique" },
  helvetica: { fontFamily: "Helvetica" },
  helveticaBold: { fontFamily: "Helvetica-Bold" },
  helveticaOblique: { fontFamily: "Helvetica-Oblique" },
  timesRoman: { fontFamily: "Times-Roman" },
  timesBold: { fontFamily: "Times-Bold" },
  timesItalic: { fontFamily: "Times-Italic" },
});

const esFontSize = StyleSheet.create({
  fs6: { fontSize: 6 },
  fs7: { fontSize: 7 },
  fs8: { fontSize: 8 },
  fs9: { fontSize: 9 },
  fs10: { fontSize: 10 },
  fs11: { fontSize: 11 },
  fs12: { fontSize: 12 },
  fs13: { fontSize: 13 },
  fs14: { fontSize: 14 },
  fs15: { fontSize: 15 },
  fs16: { fontSize: 16 },
  fs17: { fontSize: 17 },
  fs18: { fontSize: 18 },
  fs19: { fontSize: 19 },
});

//Pass above styles const genStyle(esFontSize.fs10,esFontFamily.helvetica)
const genStyle = (...arg) => {
  return Object.assign({}, ...arg);
};

export {
  esTables,
  esUtility,
  esWidth,
  esFontSize,
  esFlex,
  esBorder,
  esFontFamily,
  esPadding,
  esMargin,
  esBdrWidth,
  genStyle,
};
