import { StyleSheet, Text, View } from "@react-pdf/renderer";
import _ from "lodash";
import config from "./SummaryTableConfig";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "bold",
    fontSize: config.footerFontSize,
    borderColor: config.footerBorderColor,
    backgroundColor: config.footerBackgroundColor,
  },
  rowItem: {
    borderRight: 1,
    textAlign: "center",
    alignItems: "center",
    height: "100%",
    padding: 2,
  },
});

const SummaryTableFooter = ({ items, uniqueSizes }) => {
  let arrayOfFields = items?.[0]?.["FIELDS"];
  let arrayOfFieldValueKeys = _.keys(items?.[0]?.["FIELD_VALUES"]);

  return arrayOfFields?.map((field, fieldIndex) => {
    // Helper Variables
    let show =
      fieldIndex === Math.floor((items?.[0]?.["FIELDS"]?.length - 1) / 2);
    let lastIndex = fieldIndex === items?.[0]?.["FIELDS"]?.length - 1;

    // Sum Total
    const SUM_CTN = _.sumBy(items, (item) => Number(item["CTN"]));

    return (
      <View style={styles.row} key={fieldIndex} wrap={false}>
        <View
          style={{
            // ...styles.rowItem,
            borderRight: 1,
            alignItems: "center",
            height: "100%",
            padding: 2,
            borderBottom: lastIndex ? 1 : 0,
            width: "30%",
          }}
        >
          <Text
            style={{
              fontFamily: "Times-Bold",
            }}
          >
            {show && "G. TOTAL SUMMARY"}
          </Text>
        </View>
        <Text
          style={{
            ...styles.rowItem,
            borderBottom: 1,
            width: "10%",
            fontWeight: "bold",
            fontSize: 7,
          }}
        >
          {field}
        </Text>
        {arrayOfFieldValueKeys?.map((key, index) => {
          if (["totalPacks", "totalCtns"].includes(key)) {
            /** Sum For Percentage Field */
            let sumTotalPercentage =
              /** Sum Of Packed Quantitties Of Total Packs  & Total Pcs Of All Row */
              (
                (_.sumBy(
                  items,
                  (item) =>
                    lastIndex && Number(item["FIELD_VALUES"][`${key}`][1])
                ) /
                  /** Sum Of Required Quantitties  Of Total Packs & Total Pcs  Of All Row */
                  _.sumBy(
                    items,
                    (item) =>
                      lastIndex && Number(item["FIELD_VALUES"][`${key}`][0])
                  )) *
                100
              ).toFixed(4);

            /** Sum For Other Fields Like Ordered Quantity, Packed Quantity, Balance */
            let sumTotal = _.sumBy(items, (item) => {
              return Number(item["FIELD_VALUES"][`${key}`][fieldIndex]);
            });

            return (
              <Text
                key={key}
                style={{
                  ...styles.rowItem,
                  borderBottom: 1,
                  fontSize: 7,
                  fontWeight: "bold",
                  width: "10%",
                  borderRight: index ? 1 : 1,
                }}
              >
                {lastIndex
                  ? (_.isNaN(Number(sumTotalPercentage))
                      ? 0
                      : Number(sumTotalPercentage)) + "%"
                  : _.isNaN(Number(sumTotal))
                  ? 0
                  : Number(sumTotal)}
              </Text>
            );
          } else {
            /** Sum Of Percentage For Each Unique Size Fields */
            let sumSizePercentage =
              /** Sum Of Packed Quantitties Of Total Packs  & Total Pcs Of All Row */
              (
                (_.sumBy(items, (item) =>
                  Number(item["FIELD_VALUES"][`${key}`][1])
                ) /
                  /** Sum Of Required Quantitties  Of Total Packs & Total Pcs  Of All Row */
                  _.sumBy(items, (item) =>
                    Number(item["FIELD_VALUES"][`${key}`][0])
                  )) *
                100
              ).toFixed(4);
            /** Sum For Each Unique Size Fields */
            let sumSize = _.sumBy(items, (item) => {
              return Number(item["FIELD_VALUES"][`${key}`][fieldIndex]);
            });
            return (
              <Text
                key={key}
                style={{
                  ...styles.rowItem,
                  borderBottom: 1,
                  fontSize: 6,
                  width: `${40 / uniqueSizes.length}%`,
                }}
              >
                {lastIndex
                  ? (_.isNaN(Number(sumSizePercentage))
                      ? 0
                      : Number(sumSizePercentage)) + "%"
                  : _.isNaN(Number(sumSize))
                  ? 0
                  : Number(sumSize)}
              </Text>
            );
          }
        })}
      </View>
    );
  });
};

export default SummaryTableFooter;
