import _ from "lodash";

// Finding Unique Sizes
const plGetUniqueSizes = (APPDATA) => {
  return _.uniq(
    _.map(
      _.filter(APPDATA, (row, index) => !_.isEmpty(row)),
      (item) => item.size
    )
  );
};

/*
let uniqueSizes = getUniqueSizes(APPDATA);
  let groupedData = _(_.filter(APPDATA, (row, index) => !_.isEmpty(row)))
    .groupBy((item) => `${item.style}~*~${item.shade}`)
    .value();
*/

const plGetSummaryData = (APPDATA) => {
  let uniqueSizes = plGetUniqueSizes(APPDATA);
  let groupedData = _(_.filter(APPDATA, (row, index) => !_.isEmpty(row)))
    .groupBy((item) => `${item.style}~*~${item.shade}`)
    .value();

  let mappedSummaryData = _.map(groupedData, (item, key) => {
    let styleNumber = item?.[0].style;
    let shade = item?.[0].shade;
    let po_number = item?.[0].po_number;

    let total_packs_req_qty = _.sumBy(item, (obj) => {
      return Number(obj["ordered_qty_final"]);
    });

    let total_packs_pakd_qty = _.sumBy(item, (obj) =>
      //Number(obj["po_pck_qty_summary"])
      Number(obj["total_packs_packed_qty"])
    );

    let total_packs_balance = _.sumBy(item, (obj) =>
      //Number(obj["deviation_summary"])
      Number(obj["balance"])
    );
    /* let total_packs_percentage = parseFloat(
      (_.sumBy(item, (obj) => Number(obj["po_pck_qty_summary"])) /
        _.sumBy(item, (obj) => Number(obj["po_ord_qty_sumary"]))) *
        100
    ); */
    let total_packs_percentage = parseFloat(
      (_.sumBy(item, (obj) => Number(obj["total_packs_packed_qty"])) /
        _.sumBy(item, (obj) => Number(obj["ordered_qty_final"]))) *
        100
    );

    total_packs_percentage = _.isNaN(total_packs_percentage)
      ? 0
      : total_packs_percentage.toFixed(2);

    let total_pcs_req_qty = _.sumBy(item, (obj) =>
      Number(obj["ordered_qty_final"])
    );
    let total_pcs_pakd_qty = _.sumBy(item, (obj) =>
      //Number(obj["po_pck_qty_summary"] * packOf)
      Number(obj["total_packs_packed_qty"])
    );
    let total_pcs_balance = _.sumBy(item, (obj) =>
      //Number(obj["deviation_summary"] * packOf)
      Number(obj["deviation_summary"])
    );

    let total_ctn_req_qty = _.sumBy(item, (obj) =>
      Number(obj["no_of_cartons"])
    );
    let total_ctn_pakd_qty = _.sumBy(item, (obj) =>
      Number(obj["no_of_cartons"])
    );
    let total_ctn_balance = 0;
    let total_ctn_percentage = 0;

    /* Total PCs */

    // let total_pcs_percentage = parseFloat(
    //   (_.sumBy(item, (obj) => Number(obj["po_pck_qty_summary"] * packOf)) /
    //     _.sumBy(item, (obj) => Number(obj["po_ord_qty_sumary"] * packOf))) *
    //     100
    // );
    let total_pcs_percentage = parseFloat(
      (_.sumBy(item, (obj) => Number(obj["total_packs_packed_qty"])) /
        _.sumBy(item, (obj) => Number(obj["ordered_qty_final"]))) *
        100
    );

    total_pcs_percentage = _.isNaN(total_pcs_percentage)
      ? 0
      : total_pcs_percentage.toFixed(2);

    return {
      soNumber: APPDATA.sales_order_number,
      styleNumber,
      shade,
      po_number,
      fields: ["REQ QTY", "PAKD", "BALANCE", "%"],
      fieldValues: {
        ...uniqueSizes.reduce((sizeProperty, value, index) => {
          let sizeItem = _.find(item, { size: value });
          sizeProperty[value] = sizeItem
            ? [
                // sizeItem["po_ord_qty_sumary"],
                // sizeItem["po_pck_qty_summary"],
                // sizeItem["deviation_summary"],
                sizeItem["ordered_qty_final"],
                sizeItem["total_packs_packed_qty"],
                sizeItem["balance"],
                //parseFloat(sizeItem["per_change_sumary"]).toFixed(4),
                parseFloat(sizeItem["deviation_summary"].toFixed(2)),
              ]
            : Array(4).fill(0);
          return sizeProperty;
        }, {}),
        totalPacks: [
          total_packs_req_qty,
          total_packs_pakd_qty,
          total_packs_balance,
          total_packs_percentage,
        ],
        // totalPcs: [
        //   total_pcs_req_qty,
        //   total_pcs_pakd_qty,
        //   total_pcs_balance,
        //   total_pcs_percentage,
        // ],
        totalCtns: [
          total_ctn_req_qty,
          total_ctn_pakd_qty,
          total_ctn_balance,
          total_pcs_percentage,
        ],
      },
    };
  });

  const createData = (
    soNumber,
    po_number,
    styleNumber,
    shade,
    packOf,
    fields,
    fieldValues,
    ctn
  ) => {
    return {
      soNumber,
      po_number,
      styleNumber,
      shade,
      packOf,
      fields,
      fieldValues,
      ctn,
    };
  };

  const COLUMNS = [
    { id: "soNumber", label: "SO NO" },
    { id: "po_number", label: "PO NO" },
    { id: "styleNumber", label: "STYLE NO" },
    { id: "shade", label: "SHADE" },
    { id: "packOf", label: "PACK OF" },
    { id: "fields", label: "FIELDS" },
    { id: "fieldValues", label: "FIELD_VALUES" },
    { id: "ctn", label: "CTN" },
  ];

  const rows = mappedSummaryData.map((item) => {
    let _item = { ...item };
    return createData(
      _item.soNumber,
      _item.po_number,
      _item.styleNumber,
      _item.shade,
      _item.packOf,
      _item.fields,
      _item.fieldValues,
      _item.ctn
    );
  });

  const itemsData = rows.map((row) => {
    let data = {};
    COLUMNS.forEach((column) => {
      data = {
        ...data,
        [column.label]: row[column.id],
      };
    });
    return data;
  });

  console.log("itemsData", itemsData);

  const SummaryData = {
    items: itemsData,
  };

  return SummaryData;
};

export { plGetSummaryData, plGetUniqueSizes };
