const Colors = {
  blue: "#0676ed",
  green: "#73AF24",
  pink: "#ffa1c0",
  orange: "#fe8766",
  purple: "#6755df",
  grey: "#808291",
  light_grey: "#e4e4e4",
  yellow: "#ffaf00",
  black: "#000000",
  eggplant: "#200e32",
  white: "#ffffff",
  danger: "#F64E60",
  dark_royal_blue: "#001973",
  pale_grey: "#ecf5fa",
  red: "#fa0000",
  grape_purple: "#411d66",
  background_gray: "#f5f4f6",
  transparent: "transparent",
  bootstrapGreen: "#28A745",
  bootstrapDanger: "#DC3545",
  appPrimaryBlue: "#73AF24",
  scrollTopYellow: "#FFB71A",
};

export default Colors;
