import { LinearProgress } from "@material-ui/core";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { isLogin } from "./app/common/utils";
import LamourInvoice from "./app/views/buyerInvoiceDocuments/lamour/LamourInvoice";
import GenerateMergedSummary from "./app/views/packinglist/summarygeneration/GenerateMergedSummary";
import GetVendorBid from "./app/views/reverseauction/GetVendorBid";
const Login = React.lazy(() => import("./app/views/login"));
const DefaultLayout = React.lazy(() =>
  import("./app/views/layout/defaultLayout")
);
const SignUP = React.lazy(() => import("./app/views/signup"));
const ForgotPassword = React.lazy(() => import("./app/views/forgotpassword"));
const Contractual = React.lazy(() =>
  import("./app/views/Transporter/contractual")
);
const VehicleDetails = React.lazy(() =>
  import("./app/views/Transporter/vehicledetails")
);
const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};
const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() && restricted ? (
          <Redirect to="/app" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export const PratibhaLogoLoader = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="d-flex flex flex-column" style={{ minWidth: "150px" }}>
        <div className="text-center">
          <img
            src={`http://pace.pratibhasyntex.com/assets/media/logos/logo1.png`}
            width={80}
            height={85}
            alt=""
          />
        </div>
        <div className="mt-5">
          <LinearProgress
            variant="indeterminate"
            color="primary"
            style={{ height: "5px", borderRadius: "10px" }}
          />
        </div>
      </div>
    </div>
  );
};

const CustomPDFViewer = ({ children }) => {
  return (
    <PDFViewer width={"100%"} height="800px">
      <LamourInvoice />
    </PDFViewer>
  );
};

const GenerateSummaryDownload = () => {
  return (
    <div>
      {/* <GenerateMergedSummary /> */}
      <PDFDownloadLink
        document={<GenerateMergedSummary />}
        fileName="movielist.pdf"
        style={{
          textDecoration: "none",
          padding: "10px",
          color: "#4a4a4a",
          backgroundColor: "#f2f2f2",
          border: "1px solid #4a4a4a",
        }}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Pdf"
        }
      </PDFDownloadLink>
    </div>
  );
};

const App = (props) => {
  return (
    <div>
      <ToastContainer />
      <BrowserRouter>
        <React.Suspense fallback={PratibhaLogoLoader}>
          <Switch>
            <PublicRoute
              restricted={true}
              component={Login}
              path="/"
              exact
              {...props}
            />
            <PublicRoute
              restricted={false}
              component={SignUP}
              path="/signup"
              exact
              {...props}
            />
            <PublicRoute
              restricted={false}
              component={ForgotPassword}
              path="/forgotpassword"
              exact
              {...props}
            />
            <PublicRoute
              restricted={false}
              component={Contractual}
              path="/transporter/contractual"
              {...props}
            />
            <PublicRoute
              restricted={false}
              component={VehicleDetails}
              path="/transporter/vehicledetails"
              {...props}
            />
            <PublicRoute
              restricted={false}
              component={GetVendorBid}
              path="/reverse_auction/getVendorbid"
              {...props}
            />
            <PublicRoute
              restricted={false}
              component={GenerateSummaryDownload}
              path="/pdf"
              {...props}
            />
            <PublicRoute
              restricted={false}
              component={CustomPDFViewer}
              path="/buyerInvoice"
              {...props}
            />
            <PublicRoute
              restricted={false}
              component={PratibhaLogoLoader}
              path="/loading"
              {...props}
            />
            <PrivateRoute component={DefaultLayout} path="/app" {...props} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </div>
  );
};

export default App;
