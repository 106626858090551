import { Card, CardContent, Typography, withStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import _ from "lodash";
import clsx from "clsx";
import { useSetState } from "react-use";

const esStyles = (theme) => ({
  timerContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    backgroundColor: "#eee",
    borderRadius: "10px",
    gap: 2,
  },
  basePills: {
    backgroundColor: "#F18E00",
    padding: "5px",
    borderRadius: "5px",
    color: "#eee",
  },
  timer_days: {},
  timer_hours: {},
  timer_min: {},
  timer_sec: {},
});

const ExpiryTimer = ({ classes, expiryDateTime, onExpiry }) => {
  const [remainingTime, setRemainingTime] = useState();
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  useEffect(() => {
    let timer = setInterval(() => {
      var countDownDate = new Date(expiryDateTime).getTime();
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(timer);
        onExpiry();
        return;
      } else {
        setDay(days);
        setHour(hours);
        setMin(minutes);
        setSec(seconds);
      }

      //let final = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      //console.log("final",final)
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [expiryDateTime, onExpiry]);

  return (
    <>
      <div
        className="timer text-right"
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6" color="initial" className="mr-3 text-nowrap">
          Expires in
        </Typography>
        <div className={clsx("text-right", classes.timerContainer)}>
          <div
            className={clsx(
              "text-nowrap",
              classes.timer_days,
              classes.basePills
            )}
          >
            {day} day
          </div>
          <div
            className={clsx(
              "text-nowrap",
              classes.timer_hours,
              classes.basePills
            )}
          >
            {hour} hour
          </div>
          <div
            className={clsx(
              "text-nowrap",
              classes.timer_min,
              classes.basePills
            )}
          >
            {min} min
          </div>
          <div
            className={clsx(
              "text-nowrap",
              classes.timer_sec,
              classes.basePills
            )}
          >
            {sec} sec
          </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(esStyles)(ExpiryTimer);
