const config = {
  headerFontSize: "8",
  headerBackgroundColor: "white",
  headerBorderColor: "black",
  rowFontSize: "8",
  rowBackgroundColor: "white",
  rowBorderColor: "black",
  footerFontSize: "8",
  footerBackgroundColor: "white",
  footerBorderColor: "black",
};

export default config;